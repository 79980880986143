<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="选择学员"
    top="1%"
    width="90%"
    :center="true"
    :before-close="tableBack"
  >
    <div class="CourseTableBox">
      <section class="CourseTable">
        <div class="operationControl">
          <div class="searchbox" style="padding: 0">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">姓名:</span>
                <el-input
                        v-model="searchForm.name"
                        placeholder="请输入姓名"
                        maxlength="100"
                        clearable
                        size="small"
                ></el-input>
            </div>
            <div title="手机号" class="searchboxItem ci-full">
                <span class="itemLabel" style="min-width:6rem">手机号:</span>
                <el-input
                        v-model="searchForm.mobile"
                        placeholder="请输入手机号"
                        maxlength="100"
                        clearable
                        size="small"
                ></el-input>
            </div>
            <div title="工作单位" class="searchboxItem ci-full">
                <span class="itemLabel" style="min-width:6rem">工作单位:</span>
                <el-input
                        v-model="searchForm.workUnit"
                        placeholder="请输入工作单位"
                        maxlength="100"
                        clearable
                        size="small"
                ></el-input>
            </div>
            <div>
              <el-button class="bgc-bv" @click="search()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
              @selection-change="handleSelectionChange"
              :row-key="(row) => row.userCacheId"

            >
              <el-table-column
                  type="selection"
                  :reserve-selection="true"
                  :selectable="selectable"
                  width="55">
              </el-table-column>
              <el-table-column
                label="学员姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
                minWidth="100"
              />
              <el-table-column
                  label="身份证号"
                  align="center"
                  show-overflow-tooltip
                  prop="idcard"
                  minWidth="160"
              />
              <el-table-column
                  label="手机号"
                  align="center"
                  show-overflow-tooltip
                  prop="mobile"
                  minWidth="120"
              />
              <el-table-column
                  label="工作单位"
                  align="center"
                  show-overflow-tooltip
                  prop="workUnit"
                  minWidth="120"
              />

              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </section>
      
    </div>
    <span class="dialog-footer">
      <div class="dialogBtn">
        <el-button @click="tableBack">取消</el-button>
        <el-button class="bgc-bv" @click="doOk">确定</el-button>
      </div>
    </span>
<!--    <PaperShow ref="PaperShow" :modal="false" />-->
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty4.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import PaperShow from "@/views/resourse/popup/PaperShow";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";

export default {
  name: "SelectStudents",
  components: {
    Empty,
    PageNum,
    // PaperShow,
    // tree,
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      searchForm: {
          name: '',
          mobile: '',
          workUnit: '',
      },

      dialogVisible: false,
      multipleSelection: [],

      activityId: '',
      classroomId: '',
      // projectId: '',
      // ctProjectId: '',
      // EnrollmentList: [],
      // selectionKeys: [],
    };
  },
  created() {

  },
  computed: {

  },
  methods: {
      selectable(row, index) {
          if(row.userCandidateNo){
              return false
          }else{
              return true
          }
      },
    // 多选选中
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection)
    },
    search(){
        this.$refs.multipleTable.clearSelection()
        console.log(this.multipleSelection)
        this.getData()
    },
    async getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        activityId: this.activityId,
          classroomId: this.classroomId
      };
      if(this.searchForm.name){
          params.name = this.searchForm.name
      }
      if(this.searchForm.mobile){
          params.mobile = this.searchForm.mobile
      }
      if(this.searchForm.workUnit){
          params.workUnit = this.searchForm.workUnit
      }
      return this.doFetch({
        url: "/biz/examination_affairs/arrangement/selectUserCacheList",
        params,
        pageNum,
      },true,6);
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.5) * 16;
      if (opDom) {
        tHeight -= 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      let _this = this
      if(!this.multipleSelection.length){
        this.$message.error("请至少选择一名学员！");
        return
      }else{
        this.$parent.selectStudentsBack(this.multipleSelection)
        this.tableBack()
      }
    },
    tableBack() {
      this.dialogVisible = false;
      // this.ctProjectId = ''
      this.tableData = []

      this.multipleSelection = []

      this.$refs.multipleTable.clearSelection()
      this.apiData = {
        total: 0,
        size: 10,
        current: 1
      }
      this.searchForm.name = ''
      this.searchForm.mobile = ''
      this.searchForm.workUnit = ''

    },
    async showPopUp(activityId,classroomId,list) {

        this.dialogVisible = true;
        this.activityId = activityId
        this.classroomId = classroomId


      await this.getData()
        this.$nextTick(()=>{
            list.forEach((row) => {
                this.$refs.multipleTable.toggleRowSelection(row, true)
            })
        })

      this.getTableHeight();
    },
  },
};
</script>
<style lang="less">
.CourseTable {
  width: 100%;
  border: 1px solid #5c6be8;
  padding: 10px;
  border-radius: 0.5rem;
  .el-radio {
    .el-radio__label {
      display: none;
    }
  }
}
.testTime {
  // margin-left: 180px;
  padding-top: 20px;
  .itemLabel {
    display: inline-block;
    width: 130px;
    padding-bottom: 0.75rem;
    line-height: 2;
  }
  .testFomation {
    display: block;
    width: 300px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.dialogBtn {
  button {
    width: 10rem;
  }
}
</style>
