<template>
    <div class="pageContol">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">考务管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">考场安排</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a" @click="$router.back()">手动分配考场</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">{{ classroomId ? '修改' : '新增' }}考场</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="考试名称：" prop="activityName">
                                        {{ activityName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="职业名称：" prop="occupationName">
                                        {{ occupationName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="等级：" prop="levelCodeName">
                                        {{ levelCodeName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" align="right">
                                    <el-button class="bgc-bv" size="small" @click="submitData">确&nbsp;定</el-button>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="考场名称：" prop="classroomName">
                                        <el-input v-model="ruleForm.classroomName" placeholder="请输入考场名称" maxlength="100"
                                            size="small"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="考场人数：" prop="maxNum">
                                        <el-input-number v-model="ruleForm.maxNum" :precision="0" :step="1" :min="1"
                                            :max="100" :controls="false" style="width: 70px;" size="small"
                                            @change="changeExamRoomPeopleNum"></el-input-number>
                                        人
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div style="display: inline-block;width: 280px">
                                        <el-form-item label="准考证编号：" prop="candidateNo">
                                            <el-input class="hasPR" maxlength="20" show-word-limit
                                                v-model="ruleForm.candidateNo" placeholder="请输入准考证编号"
                                                size="small"></el-input>

                                        </el-form-item>
                                    </div>
                                    <div style="display: inline-block;width: 170px">
                                        <el-form-item label="+" prop="candidateNoBit" label-width="36px">
                                            <el-input-number :disabled="!ruleForm.maxNum" v-model="ruleForm.candidateNoBit"
                                                :precision="0" :step="1"
                                                :min="ruleForm.maxNum ? (('' + ruleForm.maxNum).length + 1) * 1 : 1"
                                                :max="5" :controls="false" style="width: calc(100% - 90px);"
                                                size="small"></el-input-number>

                                            位数流水号
                                        </el-form-item>
                                    </div>
                                    <div style="display: inline-block;width: 210px">
                                        <el-form-item label="流水号开始号码" prop="candidateNoStart">
                                            <el-input-number v-model="ruleForm.candidateNoStart"
                                                :disabled="!ruleForm.candidateNoBit" @change="changeStartCode"
                                                :precision="0" :step="1" :min="1"
                                                :max="getStartCodeMax(ruleForm.candidateNoBit, ruleForm.maxNum)"
                                                :controls="false" style="width: 100%;" size="small"></el-input-number>
                                        </el-form-item>
                                    </div>
                                    <div style="display: inline-block;width: 180px">
                                        <el-form-item label="至结束号码" prop="candidateNoEnd" label-width="90px">
                                            <el-input-number disabled v-model="ruleForm.candidateNoEnd" :precision="0"
                                                :step="1" :min="1" :max="99999" :controls="false" style="width: 100%;"
                                                size="small"></el-input-number>
                                        </el-form-item>
                                    </div>
                                    <div style="display: inline-block;width: 120px" v-if="ruleForm.maxNum &&
                                        ruleForm.candidateNo &&
                                        ruleForm.candidateNoBit &&
                                        ruleForm.candidateNoStart &&
                                        ruleForm.candidateNoEnd">
                                        <el-form-item label="准考证示例：" prop="example" label-width="120px">
                                            <span style="display: inline-block;min-width: 200px">
                                                {{ ruleForm.candidateNo
                                                }}{{ getSerialNumber(ruleForm.candidateNoBit, ruleForm.candidateNoStart) }}
                                            </span>

                                        </el-form-item>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div style="display: inline-block;width: 600px;">
                                        <el-form-item label="座位号编号：">
                                            <div style="display: inline-block;width: 210px;">
                                                <el-form-item label="座位号开始号码" prop="seatNoStart">
                                                    <el-input-number v-model="ruleForm.seatNoStart"
                                                        @change="changeSeatNoStart" :precision="0" :step="1" :min="1"
                                                        :max="99999" :controls="false" style="width: 100%;"
                                                        size="small"></el-input-number>
                                                </el-form-item>
                                            </div>
                                            <div style="display: inline-block;width: 180px;">
                                                <el-form-item label="至结束号码" prop="seatNoEnd" label-width="90px">
                                                    <el-input-number disabled v-model="ruleForm.seatNoEnd" :precision="0"
                                                        :step="1" :min="1" :max="99999" :controls="false"
                                                        style="width: 100%;" size="small"></el-input-number>
                                                </el-form-item>
                                            </div>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-form>

                        <el-button type="primary" plain size="small" @click="Setexamination"
                            style="margin-left: 20px">选择学员</el-button>
                        <div class="studentBox" style="margin-top: 20px;" v-if="studentList.length > 0">
                            <el-table ref="studentList" :data="studentList" size="small" tooltip-effect="dark"
                                style="width: 100%" :header-cell-style="tableHeader" stripe>
                                <el-table-column label="序号" align="center" type="index" :index="index" width="100px" />
                                <el-table-column label="学员姓名" align="center" show-overflow-tooltip prop="userName"
                                    minWidth="100" />
                                <el-table-column label="身份证号" align="center" show-overflow-tooltip prop="idcard"
                                    minWidth="160" />
                                <el-table-column label="手机号" align="center" show-overflow-tooltip prop="mobile"
                                    minWidth="120" />
                                <el-table-column label="工作单位" align="center" show-overflow-tooltip prop="workUnit"
                                    minWidth="120" />

                                <el-table-column label="操作" width="180px" align="center">
                                    <template v-slot="{ row }">
                                        <!--row.userCandidateNo 生成的准考证号-->
                                        <el-button type="text" size="mini" style="padding: 0 5px;"
                                            @click="deleteRow(row.userCacheId, row.classroomUserId)"
                                            :disabled="row.userCandidateNo">删除</el-button>
                                    </template>
                                </el-table-column>
                                <!--<Empty slot="empty" />-->
                            </el-table>
                            <p style="color: #666;font-size: 12px;margin-top: 15px;">注：座位号按照列表中学员顺序生成，一经生成准考证号，不可删除学员。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SelectStudents ref="SelectStudents" @getData="getData" />
    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";

import List from "@/mixins/List";
import { mapGetters } from "vuex";
import SelectStudents from "@/views/appraisalInstitution/examinationRoomArrangement/popup/SelectStudents.vue";
export default {
    name: "examinationRoomArrangement_manualAssignmentExaminationRoom",
    components: {
        SelectStudents,
    },
    mixins: [List],
    data() {
        return {
            classroomId: "",
            activityId: "", // 考务id(路由传过来的)
            activityName: "",
            occupationName: "",
            levelCodeName: "",
            ruleForm: {
                classroomName: '',
                maxNum: undefined,
                candidateNo: '',
                candidateNoBit: undefined,
                candidateNoStart: 1,
                candidateNoEnd: undefined,
                seatNoStart: 1,
                seatNoEnd: undefined,
            },
            rules: {
                classroomName: [
                    { required: true, message: "请输入考场名称", trigger: "blur" },
                ],
                maxNum: [
                    { required: true, message: "请输入考场人数", trigger: "blur" },
                ],
                candidateNo: [
                    { required: true, message: "请输入准考证号", trigger: "blur" },
                ],
                candidateNoBit: [
                    { required: true, message: "请输入流水号位数", trigger: "blur" },
                ],
                candidateNoStart: [
                    { required: true, message: "请输入开始号码", trigger: "blur" },
                ],
                seatNoStart: [
                    { required: true, message: "请输入开始号码", trigger: "blur" },
                ],
            },
            studentList: [],
        };
    },
    created() {

        this.classroomId = this.$route.query.classroomId || null;
        this.activityId = this.$route.query.activityId;
        this.activityName = this.$route.query.activityName;
        this.occupationName = this.$route.query.occupationName;
        this.levelCodeName = this.$route.query.levelCodeName;
        if (this.classroomId) {
            this.getInfo()
        }
    },
    computed: {
        ...mapGetters({
            downloadItems: "getDownloadItems",
        }),
    },
    methods: {
        // 获取回显信息
        getInfo() {
            let params = {
                classroomId: this.classroomId,
                activityId: this.activityId,
            }
            this.$post("/biz/examination_affairs/arrangement/getClassroomInfo", params, 3000, true, 6).then((res) => {
                console.log(res)
                this.ruleForm.classroomName = res.data.classroomName;
                this.ruleForm.maxNum = res.data.maxNum;
                this.ruleForm.candidateNo = res.data.candidateNo;
                this.ruleForm.candidateNoBit = res.data.candidateNoBit;
                this.ruleForm.candidateNoStart = res.data.candidateNoStart;
                this.ruleForm.candidateNoEnd = (this.ruleForm.candidateNoStart) * 1 + (this.ruleForm.maxNum * 1) - 1;
                this.ruleForm.seatNoStart = res.data.seatNoStart;
                this.ruleForm.seatNoEnd = (this.ruleForm.seatNoStart) * 1 + (this.ruleForm.maxNum * 1) - 1;
                this.studentList = res.data.userList;
            })
        },
        // 改变考场人数
        changeExamRoomPeopleNum(val) {
            if (val) {
                this.ruleForm.candidateNoBit = (('' + val).length + 1) * 1;
                this.ruleForm.candidateNoStart = 1;
                this.ruleForm.candidateNoEnd = (this.ruleForm.candidateNoStart) * 1 + (val * 1) - 1;
                this.ruleForm.seatNoEnd = (this.ruleForm.seatNoStart) * 1 + (this.ruleForm.maxNum * 1) - 1;
            } else {
                // 如果清空了数字
                this.ruleForm.candidateNoBit = undefined;
                this.ruleForm.candidateNoStart = 1;
                this.ruleForm.candidateNoEnd = undefined;
            }
        },
        // 生成流水号
        getSerialNumber(candidateNoBit, candidateNoStart) {
            return (Array(candidateNoBit).join("0") + candidateNoStart).slice(-candidateNoBit);
        },
        // 获取开始流水号最大值
        getStartCodeMax(candidateNoBit, maxNum) {
            return ((((('' + 9 * 11111).slice(5 - candidateNoBit * 1)) * 1) - maxNum * 1) + 1) * 1
        },
        // 改变流水号开始号码
        changeStartCode(val) {
            if (val) {
                this.ruleForm.candidateNoEnd = (val) * 1 + (this.ruleForm.maxNum * 1) - 1
            } else {
                // 如果清空了数字
                this.ruleForm.candidateNoEnd = undefined
            }
        },
        // 改变座位开始号开始号码
        changeSeatNoStart(val) {
            if (val) {
                this.ruleForm.seatNoEnd = (val) * 1 + (this.ruleForm.maxNum * 1) - 1;
            } else {
                // 如果清空了数字
                this.ruleForm.seatNoEnd = undefined
            }
        },
        // 选择学员弹窗打开
        Setexamination() {
            console.log(this.studentList)
            this.$refs.SelectStudents.showPopUp(this.activityId, this.classroomId, this.studentList);
        },
        // 从选择学员回来
        selectStudentsBack(list) {
            this.studentList = list
        },
        // 删除列表当前行
        deleteRow(userCacheId, classroomUserId = null) {
            if (classroomUserId) {
                let params = {
                    classroomUserId
                }
                this.$post("/biz/examination_affairs/arrangement/apportionHandDeleteUser ", params, 3000, true, 6).then((res) => {
                    this.studentList.forEach((e, i) => {
                        if (e.userCacheId == userCacheId) {
                            this.studentList.splice(i, 1)
                        }
                    })
                });
            } else {
                this.studentList.forEach((e, i) => {
                    if (e.userCacheId == userCacheId) {
                        this.studentList.splice(i, 1)
                    }
                })
            }

        },
        // 新增编辑提交
        submitData() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {

                    if (this.studentList.length == 0) {
                        this.$message.error('请至少选择一个学员！')
                        return
                    }
                    let arr = []
                    if (this.studentList && this.studentList.length > 0) {
                        this.studentList.forEach((e, i) => {
                            arr.push(e.userCacheId)
                        })
                    }
                    let params = {
                        activityId: this.activityId,
                        userList: arr,
                        ...this.ruleForm,

                    }
                    if (this.classroomId) {
                        params.classroomId = this.classroomId
                    }
                    this.$post("/biz/examination_affairs/arrangement/apportionHand", params, 3000, true, 6).then((res) => {
                        this.$message.success('配置成功！')
                        this.$router.back()
                    });
                    // console.log(this.)
                    console.log(params)
                }

            })
        }
    },
};
</script>
<style lang="less" scoped>
.examinationHallDataTs {
    color: red;
}

.hasPR /deep/.el-input__inner {
    padding-right: 45px;
}

/deep/.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: '';
}
</style>